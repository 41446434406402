import { ThemeProvider } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { AuthContextProvider } from './Context/AuthContext';
import { InsuranceCalculatorProvider } from './Context/Calculator/InsuranceCalculatorContext';
import LoaderProvider from './Context/LoadingContext';
import { SnackbarProvider } from './Context/SnackbarContext';
import { TrackingContextProvider } from './Context/TrackingContext';
import BaseCalculator from './components/BaseCalculator/BaseCalculator';
import Address from './pages/Address';
import Banking from './pages/Banking';
import Base from './pages/Base';
import Compliance from './pages/Compliance';
import Home from './pages/Home';
import Login from './pages/Login';
import PolicyDetails from './pages/PolicyDetails/PolicyDetails';
import Register from './pages/Register';
import TermsAndConditions from './pages/TermsAndConditions';
import { getTheme } from './utils/themeLoader';
import { StepProvider } from './Context/StepContext';
import Navigator from './pages/Navigator';
import PaymentMethod from './pages/PaymentMethod';
import CWallet from './pages/CWallet';

function App() {

  const theme = getTheme();
  return (
    <>
    <StepProvider>
      <ThemeProvider theme={theme} >
        <SnackbarProvider>
          <LoaderProvider>
            <TrackingContextProvider>
              <AuthContextProvider>
                  <InsuranceCalculatorProvider>
                    <Routes>
                      <Route path="/" element={<Base />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/base" element={<BaseCalculator />} />
                      </Route>

                      <Route path="/customer" element={<Navigator />}>
                        <Route path="register" element={<Register />} />
                        <Route path="address" element={<Address />} />
                        <Route path="compliance" element={<Compliance />} />
                        <Route path="policy" element={<PolicyDetails />} />
                        <Route path="banking" element={<Banking />} />
                        <Route path="paymentmethod" element={<PaymentMethod />} />
                        <Route path="wallet" element={<CWallet />} />
                        <Route path="sign" element={<TermsAndConditions />} />
                      </Route>
                    </Routes>
                  </InsuranceCalculatorProvider>
              </AuthContextProvider >
            </TrackingContextProvider>
          </LoaderProvider>
        </SnackbarProvider>
      </ThemeProvider>
      </StepProvider>
    </>
  )
}

export default App


