import { Typography } from "@mui/material";

export const ConditionsText = () => {
    return (
        <>
            <Typography variant="body1"><b>Administrator</b> shall mean Triarc (Pty) LTD with Registration Number 2012/011172/07 and FSP No. 45009</Typography>
            <Typography variant="body1"><b>Insured Life</b> shall mean the person on whose life we base the premiums for the Policy and for whom cover is provided in the event of a Claim Event. (Includes you, your Spouse, Parents, Parent-in-law and Extended Family).</Typography>
            <Typography variant="body1"><b>Insurer</b> shall mean Guardrisk Life Limited, a licensed life insurer and an authorized financial services provider (registration Number: 199/013922/06 and FSP No. 76). </Typography>
            <Typography variant="body1"><b>Policyholder</b> shall mean the owner of the Policy and the only person who can make changes to the existing Benefit(s).</Typography>
            <Typography variant="body1"><b>Premium Payer</b> means the person or legal entity from whose bank account we will collect the premiums for this Policy. If the Premium Payer is different to the Policyholder, this person will have no rights to the policy except to pay the monthly premium.</Typography>
            <Typography variant="body1"><b>Application</b> the manual application form or application you have done via the call center telephonically.</Typography>
            <Typography variant="body1"><b>Beneficiary</b> means the person nominated by you to receive the Benefit/s in terms of this Policy if you die. If you do not nominate a person to be your beneficiary, you agree that the Claimant will be your beneficiary. You will be the beneficiary in the event of a death of any other Insured Life insured other than you.</Typography>
            <Typography variant="body1"><b>Benefits</b> the funeral plan selected by the Policyholder shall be stated in the Policy Schedule. </Typography>
            <Typography variant="body1"><b>Child</b> means the unmarried minor child who has not yet attained the age of 21 years and shall include natural children, legally adopted children and stepchildren; or a child who is physically and mentally disabled and fully dependent on the member shall have no age limit in respect of cover.</Typography>
            <Typography variant="body1"><b>Claimant</b> means the person who makes a Claim in relation to this Policy and who must be you, or if you have died, your surviving Spouse, a person who is financially dependent on you, your sibling (including a half sibling or step sibling) or Parent (including a natural or legally adoptive Parent).</Typography>
            <Typography variant="body1"><b>Dependent</b> means, the Spouse, Child, Parent, Parent-in-law and Extended Family of the Policyholder. </Typography>
            <Typography variant="body1"><b>Exclusion</b> shall mean a loss or risk not covered under this Policy. Any Claim arising from an Exclusion will not be paid.</Typography>
            <Typography variant="body1"><b>Extended Family</b> means another Spouse who meets the definition of a Spouse, who is not registered as the Spouse on this Policy. Any Child who meets the definition of a Child but is not registered as a Child on this Policy including Children older than 21 years of age. A blood relative who is financially dependent on you or your Spouse. </Typography>
            <Typography variant="body1"><b>Maximum lives insured</b>
                One Main Member
                One Spouse
                Maximum of five Children under the age of 21
                Maximum of four Parent and/or Parents-in-law
                Maximum of four Extended Family members</Typography>
            <Typography variant="body1"><b>Parent and Parent-in-Law</b> means the natural parent of you or your Spouse or the parents who legally adopted you or your Spouse.</Typography>
            <Typography variant="body1"><b>Policy</b> refers to the Triarc Policy Schedule and Application form and any accepted changes that are made to your Policy.</Typography>
            <Typography variant="body1"><b>Policy Schedule</b> is the summary of the Policy that Triarc sends to you when Triarc has activated your Application for cover or any changes that are made to your Policy with a revised Schedule being issued.</Typography>
            <Typography variant="body1"><b>Spouse</b> means a person who is married to the Member by law, tribal custom or tenets of any religion. A person who has resided with or in the members home for a minimum of 12 uninterrupted months and who is regarded by the members family and community as being the permanent life partner of the member will also be regarded as a spouse in terms of this Contract. </Typography>

            <Typography variant="body1"><b>The Premiums</b> All premiums as stated in the Policy Schedule must be paid by debit order. If the premium payer does not pay the premiums when they are due, Triarc may, after expiry of the grace period, cancel the Policy from the date the premium was due.
                Triarc will only consider the premium as paid when our bank account is credited with the premium, provided that the premium payer did not reverse the payment.
                The contract premium is the sum of the individual contractual benefit premiums for the benefits and the binder fee.
                Premiums are payable monthly in advance.
                The premium and Benefits are guaranteed for the first 12 (twelve) months. The Premium will be reviewed annually in relation to the Benefits on the anniversary date from the Policy Start Date. Any changes to the premium will be notified in writing to the Policyholder 31 (thirty-one) Days before such increase takes effect.
                The premium rate will automatically increase when the Policyholder, Child, Parent and Parent-in-Law, Extended Family moves into a new age band and notification of such premium change will be communicated to the Policyholder 31 days prior to the change taking place.
                If we could not collect the first premium under this Policy, you will not enjoy cover until we have received all the premiums due under this Policy, and provided that your Policy has not lapsed.
                If we do not receive a premium within 31 (thirty-one) Days of it becoming due, we will cancel the Policy.
                If you apply to make changes to this Policy, the premium rates charged for the changes will be according to the premium rates applicable at the time of the change and not the premium rates that applied at the Policy Start Date.
                The premium rates are set out in the Policy Schedule. All charges for this Policy are included in the premium rates.
                The premium includes a 11% binder fee payable to Triarc (Pty) Ltd and 20% commission payable as part of the monthly premium.
                You confirm that Triarc may collect Premiums
                You hereby authorize Triarc to collect premiums via debit order from your bank account.
                Premiums, charges and fees will be collected on the first debit order day or the first working day thereafter, should the debit order day fall on a Sunday or South African Public Holiday.
                If the premium is paid by someone other than the Policyholder, then that person’s bank will treat every payment instruction to pay the premiums, charges and fees to us as if it came from that person personally. The premium payer may cancel the debit order at any time, but this may lead to the policy being cancelled if you (or the premium payer) do not give us new banking details and/or if the premium is not paid in time.
                You know and understand that if someone else is paying the premiums it is your responsibility to ensure that there is always sufficient funds to pay the premiums.
                Neither you nor the premium payer can request a refund from Triarc when they were due and legally owing to us.
                Triarc is not responsible for any loss or harm you or the premium payer may suffer because the banking details supplied to Triarc are incorrect. It is your and/or the premium payers’ responsibility to let Triarc know if the banking details have changed.
                Where the Policy has lapsed, a request to reinstate benefits must be made to the Insurer in writing. The Insurer reserves the right to either accept or decline the reinstatement of the Policy.</Typography>

            <Typography variant="body1"><b>Cancelling the Policy</b> Cooling off period – means if you find that this Policy or any of the benefits that it contains is not what you require, you may cancel it. Please do so by informing us in writing, no later than 31 days from the starting date of this Policy. We will refund the total amount of all premiums that you have paid, as long as we have not yet paid any benefit and you have not claimed under a benefit and a claim event has not yet occurred. We will, however, deduct the cost of any risk cover that you enjoyed.
                Cancelling your Policy – Should you wish to cancel your Policy after the expiry of the cooling-off period mentioned above, you need to inform Triarc in writing, giving 1 (one) calendar month notice. No premium paid after the cooling-off period will be refunded.
                Restarting a Cancelled Policy – we will consider restarting the Policy within 6 (six) months of the date of cancellation. You will not automatically qualify for the benefit(s) you had before you cancelled the Policy. We will restart your Policy only if you meet our requirements.</Typography>

            <Typography variant="body1"><b>if your premium is not received</b>, the Insurer will endeavor to collect the arrear premium; however, your cover will remain in force for a period of 31(thirty-one) Days (the grace period) and if the arrears is not paid by the expiry of the grace period, the Policy will lapse, and all cover will cease. Any claim event arising within the grace period will be considered for a claim and if approved, any arrear premium will be deducted from the benefit payable. The Insurer will inform you in writing should your premium remains unpaid, and if cover has lapsed or been cancelled.</Typography>

            <Typography variant="body1"><b>Financial Advisors</b> Triarc does not provide any financial advice to you. Financial advice must be obtained from an accredited financial advisor.
                Triarc will only allow financial advisors who are authorised and licensed by the Financial Sector Conduct Authority (FSCA) to act as financial services providers on your behalf.
                You cannot hold Triarc legally responsible for suffering any loss or damage if the financial advisor is not authorised as a financial services provider, and the processing of this application form is delayed and afterwards cancelled because of that fact.
                Unless the financial advisor is an employee of Triarc, you cannot hold Triarc legally responsible or liable for suffering any loss or damage as a result of the financial advisor acting outside the scope of his/her/its authority and license conditions, furthermore, the financial advisor has the responsibility to act within his/her/its license conditions and authority.
                The financial advisor must ensure that you receive and understand all appropriate, product and fee information.</Typography>

            <Typography variant="body1"><b>Waiting Periods</b> There shall be a waiting period of 12 (twelve) months from the date of acceptance in respect of death by suicide on all Insured Lives under the Policy. The exclusion and limitations shall be calculated with reference to the commencement date of the Policy or any subsequent reinstatement date of the Policy.
                A Waiting Period applies from the start date or reinstatement date of this Policy, on all benefits for any Insured Life. A Waiting Period applies from the date a new Insured Life is added to the Policy. No funeral cover amount will be paid for a Natural Death during the Waiting Period.
                The waiting period for natural death will be:
                6 (six) calendar months from the start of the Policy for the Principal Member, Spouse, Parents and Parents-in-Law.
                6 (six) calendar months from the start of the Policy for Extended Family.</Typography>

            <Typography variant="body1">Notwithstanding any provision to the contrary within this Policy and its associated Policy Schedule or any endorsements thereto, it is agreed that this Policy excludes any loss or expense of whatsoever nature directly caused by, resulting from, or in connection with willing participation by the Insured Life in any of the following:
                - Terrorism, War (whether declared or not), Civil commotion or riot;
                - The Insured’s active participation in the commission of a criminal activity that results in a Claim Event;
                - Use of nuclear, biological or chemical weapons, or any radioactive contamination, whether directly or indirectly;</Typography>

            <Typography variant="body1"><b>Disclaimer</b> Triarc will take all reasonable steps to verify the identity of persons submitting information, or instructions against this Policy and ensure the security and confidentiality of the information that has been submitted however Triarc indemnifies itself against any possible loss that you or a third party may suffer as a result of the misuse, misapplication or misinterpretation by persons submitting such information or instructions.
                Triarc ensures the integrity and security of its electronic data systems and warrants that it will comply with all relevant legislation relating to electronic communications.</Typography>

            <Typography variant="body1"><b>Relationship between Guardrisk Life and Triarc:</b> Guardrisk Life and Triarc have concluded a shareholder and subscription agreement that entitles Triarc to place insurance business with the Guardrisk Life. The shareholder and subscription agreement entitles Triarc to share in the profits and losses generated by the insurance business. Guardrisk Life may distribute dividends, at the sole discretion of its Board of Directors, to Triarc during the existence of the policy.</Typography>

            <Typography variant="body1"><b>Processing of Personal Information in terms of the Protection of Personal Information Act 4 of 2013</b>
                Your privacy is of utmost importance to Us. We will take the necessary measures to ensure that any and all information, provided by you or which is collected from you, is processed in accordance with the provisions of the Protection of Personal Information Act 4 of 2013 and further, is stored in a safe and secure manner. You hereby agree to give honest, accurate, and up-to-date Personal Information and to maintain and update such information when necessary. You accept that your Personal Information collected by Us may be used for the following reasons:
                - to establish and verify your identity in terms of the Applicable Laws.
                - to enable Us to fulfil our obligations in terms of this Policy.
                - to enable Us to take the necessary measures to prevent any suspicious or fraudulent activity in terms of the Applicable Laws; and
                - reporting to the relevant Regulatory Authority/Body, in terms of the Applicable Laws.
                Unless consented to by yourself, we will not sell, exchange, transfer, rent, or otherwise make available your Personal Information (such as your name, address, email address, telephone, or fax number) to any other parties and you indemnify Us from any claims resulting from disclosures made with your consent. You understand that if the Administrator/Insurer has utilized your Personal Information contrary to the Applicable Laws, you have the right to lodge a complaint with Guardrisk or with the Information Regulator.</Typography>

        </>
    );
}

export const DebitOrderAuthText = () => {
    return (
        <>
            <Typography variant="body1">I/We hereby authorise Triarc to issue and deliver payment instructions to the Bank for collection against my/our above-mentioned Bank account at my/our above-mentioned Bank (or any other bank or branch to which I/we may transfer my/our account) on condition that the sum of such payment instruction will never exceed my/our obligations as agreed in our contract/agreement and continuing until this Authority and Mandate is terminated by me/us by giving you notice in writing of not less than 20 ordinary working days, and sent to you in writing. The payment day may be aligned to an earlier salary in December if agreed by you and Triarc.</Typography>

            <Typography variant="body1">The individual payment instructions so authorised to be issued must be issued and delivered and will continue monthly from the start date as indicated above. In the event that the payment day falls on a Sunday, or recognised South African public holiday, the payment day will automatically be the very next business day. Furthermore, if there are insufficient funds in my account to meet the obligation, you are entitled to track my account and represent the instruction for payment as soon as sufficient funds are available in my account.</Typography>

            <Typography variant="body1">I/we understand that the withdrawals hereby authorised will be processed through a computerised system provided by the South African Banks. I also understand that details of each withdrawal will be printed on my bank statement. Such must contain a number, which must be included in the said payment instruction and if provided to me should enable me to identify the Agreement.</Typography>

            <Typography variant="body1">I/we acknowledge that all payment instructions issued by you shall be treated by me/our above-mentioned bank as if the instructions had been issued by me/us personally. I/we agree that although this Authority and Mandate may be cancelled me/us, such cancellation will not cancel the Agreement. I/we shall not be entitled to any refund of amounts which you have withdrawn while this authority was in force, if such amounts were legally owing to you. I/we acknowledge that this Authority may be ceded to or assigned to a third party if the agreement is also ceded or assigned to that third party, but in the absence of such assignment of the Agreement, this Authority and Mandate cannot be assigned to any third party.</Typography>

            <Typography variant="body1">We will confirm your Authority and Mandate in writing prior to processing the debit order against your account.</Typography>

            <Typography variant="body1">To verify the banking details, Triarc may request a bank letter, or a copy of a bank statement.</Typography>
        </>
    )
}