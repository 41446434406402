import { TextField, TextFieldProps, styled } from '@mui/material';
import { Controller, FieldValues, Path } from 'react-hook-form';

const StyledTextField = styled(TextField)(() => ({

  marginBottom: '0px',
  '& .MuiFormHelperText-root': {
    minHeight: '0px', 
  },
}));

type FormInputProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any; 
  label: string;
} & Omit<TextFieldProps, 'name' | 'defaultValue'>;

const FormInput = <TFieldValues extends FieldValues>({
  name,
  control,
  label,
  ...rest
}: FormInputProps<TFieldValues>) => {
  return (
  <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledTextField
          {...field}
          label={label}
          error={!!error}
          helperText={error ? error.message : ''}
          value={field.value ?? ''}
          {...rest}
          
        />
      )}
    />
  );
};

export default FormInput;
