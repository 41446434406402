// theme.js
import { createTheme } from '@mui/material/styles';

export const catholicTheme = createTheme({
  palette: {
    primary: {
      main: '#F05454',
    },
    secondary: {
      main: '#ed8c8c',
    },

  },
  typography: {
    fontFamily: 'Lato',
    h1: {
      fontSize: '3.4rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '3rem',
    },
    h3: {
      fontSize: '2.4rem',
    }
  },
  components: {
    // Component-specific style overrides
    MuiButton: {
      styleOverrides: {
        root: {
          // Example override: making the default button size larger
          fontSize: '1rem',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          // Add your root styles here if needed
        },
        track: {
          borderRadius: '3px',
          height: '10px',
          // Add other track styles here
        },
        thumb: {
          height: '40px',
          width: '40px',
          backgroundColor: '#f1f0f0',
          // `textColor` isn't a valid MUI slider style, you might want to adjust another part or use a different way to set text color
          // textColor: '#000000',
        },
        rail: {
          borderRadius: '3px',
          height: '10px',
          backgroundColor: 'rgb(205, 205, 205)',
        },
      },
    },
    // You can also extend the theme with custom properties
    custom: {
      drawerWidth: 240, // Example custom property
    },
  },
});

export default catholicTheme;
