
import { useTheme } from '@mui/material/styles';
import Decimal from 'decimal.js';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../Context/AuthContext';
import { useInsuranceCalculator } from '../../../Context/Calculator/InsuranceCalculatorContext';
import { InsurancePlan } from '../../../Context/Calculator/InsuranceModels';
import SnackbarContext from '../../../Context/SnackbarContext';
import CheckMarkIcon from '../../Shared/CheckMarkIcons';
import { currencyNumber } from "../../Shared/Regex";
import styles from './BaseCardTile.module.scss';
interface CardTileProps {
    insurancePlan?: InsurancePlan
}

const BaseCardTile: React.FC<CardTileProps> = ({ insurancePlan }) => {
    const theme = useTheme();
    let navigate = useNavigate();
    let { isLoggedIn } = useContext(AuthContext);
    const { openSnackbar } = useContext(SnackbarContext);

    const { setInsurancePlan, calculatePremium } = useInsuranceCalculator();

    const handleNavigation = () => {
        if (isLoggedIn) {
            navigate('customer/register');
        }
        else {
            openSnackbar("Please Login", "success");
            navigate('/login');
        }
    }

    return (
        <>
            <div className={styles['card-tile-container']}>
                <div className={styles['card-tile-top-container']}>
                    <div className={styles['card-tile-heading']}>
                        Insurance
                    </div>
                    <div className={styles['card-tile-title']}>
                        Plan
                    </div>
                    <div className={styles['card-tile-price']}>
                        R {currencyNumber(25)}
                    </div>
                    <div className={styles['card-tile-monthly']}>
                        per month
                    </div>
                </div>
                <div className={styles['card-tile-body-container']}>

                    <div className={styles['card-tile-content']}>

                        <div style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bold', marginBottom: 10, marginTop: 10 }}>Benefit amounts</div>
                        <div className={styles['card-tile-content-item']}>{<CheckMarkIcon />}Principal Life R{currencyNumber(5000)}</div>

                        <div className={styles['card-tile-content-item']}>
                            {<CheckMarkIcon />}
                            Spouse R{currencyNumber(5000)}
                        </div>
                        <div className={styles['card-tile-content-item']}>
                            {<CheckMarkIcon />}
                            Children (14-21) R{currencyNumber(5000)}
                        </div>
                        <div className={styles['card-tile-content-item']}>
                            {<CheckMarkIcon />}
                            Children (7-13) R{currencyNumber(5000)}
                        </div>
                        <div className={styles['card-tile-content-item']}>
                            {<CheckMarkIcon />}
                            Age (6) R{currencyNumber(5000)}
                        </div>
                        <div className={styles['card-tile-content-item']}>
                            {<CheckMarkIcon />}
                            Stillborn R{currencyNumber(2500)}
                        </div>
                    </div>
                    <div onClick={handleNavigation} className={styles['card-tile-button']}>
                        Proceed
                    </div>
                </div>
            </div>
        </>
    );
}

export default BaseCardTile;
