import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Register from '../pages/Register';
import Address from '../pages/Address';
import PolicyDetails from '../pages/PolicyDetails/PolicyDetails';
import Compliance from '../pages/Compliance';
import Banking, { BANKING_DETAILS_KEY } from '../pages/Banking';
import TermsAndConditions from '../pages/TermsAndConditions';
import PaymentMethod, { PAYMENT_METHOD_KEY } from '../pages/PaymentMethod';
import CWallet, { C_WALLET_DETAILS_KEY } from '../pages/CWallet';
import { paymentMethod } from '../utils/enums';

interface StepContextType {
  currentStep: number | null;
  setCurrentStep: (number: number) => void;
  steps: any[] | null;
  nextStep: () => void;
  inJourney: boolean | null;
  selectedPayment: paymentMethod | null;
  setSelectedPayment: (payment: paymentMethod | null) => void;
  resumeJourney: () => void;
  resetSteps : () => void;
}

const StepContext = createContext<StepContextType | undefined>(undefined);


export const StepProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState<number>();
  const [steps, setSteps] = useState([]);
  const [inJourney, setInJourney] = useState(true);
  const [selectedPayment, setSelectedPayment] = useState<paymentMethod | null>(paymentMethod.DebitOrder);
  const location = useLocation();
  const navigate = useNavigate();


  const host = window.location.host;
  const subdomain = host.split('.')[0];

  const cartrackSubdomain = import.meta.env.VITE_CARTRACK_SUBDOMAIN;
  const catholicSubdomain = import.meta.env.VITE_CATHOLICCONNECT_SUBDOMAIN;

  const cartrackSteps = [
    { path: 'register', component: <Register /> },
    { path: 'address', component: <Address /> },
    { path: 'compliance', component: <Compliance /> },
    { path: 'policy', component: <PolicyDetails /> },
    { path: 'banking', component: <Banking /> },
    { path: 'sign', component: <TermsAndConditions /> },
  ];

  const catholicconnectSteps = [
    { path: 'register', component: <Register /> },
    { path: 'address', component: <Address /> },
    { path: 'compliance', component: <Compliance /> },
    { path: 'policy', component: <PolicyDetails /> },
    { path: 'paymentMethod', component: <PaymentMethod /> },
    ...(selectedPayment === paymentMethod.CatholicWallet
      ? [{ path: 'wallet', component: <CWallet /> }]
      : selectedPayment === paymentMethod.DebitOrder
        ? [{ path: 'banking', component: <Banking /> }]
        : []),
    { path: 'sign', component: <TermsAndConditions /> },

  ];


  const getStepsBaseOnSubdomain = () => {
    switch (subdomain) {
      case cartrackSubdomain:
        return cartrackSteps;
      case catholicSubdomain:
        return catholicconnectSteps;
      default:
        return [];
    }
  };

  const resumeJourney = () => {
    const savedStep = localStorage.getItem("currentStep");
    if (savedStep) {
      const step = parseInt(savedStep, 10);
      navigate('/customer/' + steps[step].path);
    }
  };

  const nextStep = () => {
    const nextIndex = currentStep + 1;
    if (nextIndex < steps.length) {
      setCurrentStep(nextIndex);
      localStorage.setItem("currentStep", nextIndex.toString());
      navigate('/customer/' + steps[nextIndex].path);
    }
  };

  const resetSteps = () => {
    setCurrentStep(0);
    // clean up stored user data
    localStorage.removeItem(PAYMENT_METHOD_KEY);
    localStorage.removeItem(BANKING_DETAILS_KEY);
    localStorage.removeItem(C_WALLET_DETAILS_KEY);
  }

  useEffect(() => {
    const savedStep = localStorage.getItem("currentStep");
    if (savedStep) {
      setCurrentStep(parseInt(savedStep, 10));
    }
  }, []);

  useEffect(() => {
    const stepsBasedOnSubdomain = getStepsBaseOnSubdomain();
    setSteps(stepsBasedOnSubdomain);
  }, [subdomain,selectedPayment]);

  // when a customer clicks back on the browser
  useEffect(() => {
    const currentPath = location.pathname;
    const stepIndex = steps.findIndex(step => `/customer/${step.path}` === currentPath);

    if (stepIndex !== -1) {
      setCurrentStep(stepIndex);
      localStorage.setItem("currentStep", stepIndex.toString());
    }
  }, [location, steps, setCurrentStep]);

  return (
    <StepContext.Provider value={{ currentStep, setCurrentStep, nextStep, steps, inJourney, resumeJourney, resetSteps, selectedPayment, setSelectedPayment }}>
      {children}
    </StepContext.Provider>
  );
};

export const useSteps = (): StepContextType => {
  const context = useContext(StepContext);
  if (context === undefined) {
    throw new Error('useSteps must be used within a StepProvider');
  }
  return context;
};
