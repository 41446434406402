import { zodResolver } from '@hookform/resolvers/zod';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, CssBaseline, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useLoader } from '../Context/LoadingContext';
import SnackbarContext from '../Context/SnackbarContext';
import { Client, ComplianceCreate_Request, ComplianceCreate_Request_CloseAssociateDto, ComplianceCreate_Request_DomesticInfluentialDto, ComplianceCreate_Request_ExistingPolicyDto, ComplianceCreate_Request_ForeignPublicOfficialDto, ComplianceCreate_Request_PoliticallyExposedDto, IComplianceCreate_Request } from '../api/insurance-api';
import FormInput from '../components/Form/FormInput';
import FormSwitch from '../components/Form/FormSwitch';
import SharedButton from '../components/Shared/SharedButton/SharedButton';
import SharedPopover from '../components/Shared/SharedPopover/SharedPopover';
import { getDynamicApiUrl } from '../utils/themeLoader';
import { complianceSchema } from '../validation/schema';
import { useSteps } from '../Context/StepContext';
import AuthContext from '../Context/AuthContext';

const Compliance: React.FC = () => {
    const { customerId } = useContext(AuthContext);
    const { openSnackbar } = useContext(SnackbarContext);
    const theme = useTheme();
    const apiclient = new Client(getDynamicApiUrl());
    const { showLoader, hideLoader } = useLoader();
    const { nextStep } = useSteps();

    type ComplianceInputs = z.infer<typeof complianceSchema>;

    const { control, watch, formState: { errors }, handleSubmit } = useForm({
        mode: 'onChange',
        resolver: zodResolver(complianceSchema)
    });

    const AlternatePolicy = watch("alternatePolicy");
    const CancelAlternatePolicy = watch("cancelAlternatePolicy");
    const waitingPeriodExpired = watch("waitingPeriodExpired");
    const PoliticallyExposedPerson = watch("politicallyExposedPerson");
    const DomesticProminentInfluential = watch("domesticProminentInfluential");
    const ForeignProminentPublicOfficial = watch("foreignProminentPublicOfficial");
    const KnownCloseAssociate = watch("knownCloseAssociate");

    const onSubmit = async (data: ComplianceInputs) => {

        const complianceRequest: IComplianceCreate_Request = {
            existingPolicy: new ComplianceCreate_Request_ExistingPolicyDto({
                willCancel: data.cancelAlternatePolicy !== undefined ? data.cancelAlternatePolicy : false,
                waitingPeriodExpired: data.waitingPeriodExpired !== undefined ? data.waitingPeriodExpired : false,
                policyNumber: data.alternatePolicyNumber !== undefined ? data.alternatePolicyNumber : 'none',
                policyName: data.alternatePolicyName !== undefined ? data.alternatePolicyName : 'none',
                sameBenefit: data.alternatePolicyBenefitsSame !== undefined ? data.alternatePolicyBenefitsSame : false,
                policyPaid: data.alternatePolicyPaidUp !== undefined ? data.alternatePolicyPaidUp : false
            })
        };

        if (data.politicallyExposedPerson) {
            complianceRequest.politicallyExposed = new ComplianceCreate_Request_PoliticallyExposedDto({
                reason: data.politicallyExposedPersonReason
            })
        }

        if (data.domesticProminentInfluential) {
            complianceRequest.domesticInfluential = new ComplianceCreate_Request_DomesticInfluentialDto({
                reason: data.domesticProminentInfluentialReason,
            })
        }

        if (data.foreignProminentPublicOfficial) {
            complianceRequest.foreignPublicOfficial = new ComplianceCreate_Request_ForeignPublicOfficialDto({
                reason: data.foreignProminentPublicOfficialReason
            })
        }

        if (data.knownCloseAssociate) {
            complianceRequest.closeAssociate = new ComplianceCreate_Request_CloseAssociateDto({
                reason: data.knownCloseAssociateReason
            })
        }

        console.log("complianceRequest : ", complianceRequest);

        try {
            showLoader();
            // TODO: Confirm with Eugene if this was left out intentionally ⬇️
            await apiclient.customerComplianceCreate(customerId, new ComplianceCreate_Request(complianceRequest));
            openSnackbar("Compliance saved", "success");
            nextStep();
        } catch (error) {
            openSnackbar("Failed - please try again", "error");
        }
        finally {
            hideLoader();
        }
    };

    return (
        <>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant='h3' sx={{ color: '#777774' }}>Compliance Details</Typography>
                    <Typography variant='h5'> Please enter your details</Typography>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormSwitch
                                    name="alternatePolicy"
                                    control={control}
                                    label="Are you currently insured on an alternate funeral policy?"
                                />
                            </Grid>
                            {
                                AlternatePolicy &&
                                <Grid item xs={12}>
                                    <FormSwitch
                                        name="cancelAlternatePolicy"
                                        control={control}
                                        label="Will you be cancelling that policy and replacing it with this one?"
                                    />
                                </Grid>
                            }
                            {
                                AlternatePolicy && CancelAlternatePolicy && <Grid xs={12}>
                                    <FormSwitch
                                        name="waitingPeriodExpired"
                                        control={control}
                                        label="Has the waiting period for natural death already expired on the alternate policy?"
                                    />
                                </Grid>
                            }
                            {
                                AlternatePolicy && CancelAlternatePolicy && waitingPeriodExpired &&
                                <>
                                    <Grid item xs={12}>
                                        <FormInput
                                            name="alternatePolicyNumber"
                                            control={control}
                                            label="Alternate Policy Number"
                                            error={!!errors.alternatePolicyNumber}
                                            helperText={errors.alternatePolicyNumber?.message?.toString()}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormInput
                                            name="alternatePolicyName"
                                            control={control}
                                            label="Alternate Policy Name"
                                            error={!!errors.alternatePolicyName}
                                            helperText={errors.alternatePolicyName?.message?.toString()}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormSwitch
                                            name="alternatePolicyPaidUp"
                                            control={control}
                                            label="Alternate Policy Paid Up?"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormSwitch
                                            name="alternatePolicyBenefitsSame"
                                            control={control}
                                            label="Alternate Policy the same?"
                                        />
                                    </Grid>
                                </>
                            }
                            {/* <Accordion> */}
                            <Accordion sx={{ backgroundColor: theme.palette.primary.light, borderRadius: '4px' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: '#fff', fontSize: '40px' }} />}
                                >
                                    <Typography sx={{ color: '#fff' }}>Anti-money Laundering Provisions And Influential Persons Declaration
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ color: '#fff' }}>
                                            The Financial Intelligence Centre Act (FICA) requires that we know if you are an influential person as explained in the Act. It differentiates between a politically exposed person, domestic prominent influential person, foreign prominent public official and a known close associate or family of domestic prominent influential persons and foreign prominent public officials. More than one of the definitions can apply to the same person.
                                            Please read the below explanations and indicate if any apply to you and give your reason.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="flex items-center">
                                <FormSwitch
                                    name="politicallyExposedPerson"
                                    control={control}
                                    label="Are you a Politically exposed person ?"
                                />
                                <div className="ml-2 mb-5">
                                    <SharedPopover type={"politicallyExposedPerson"} />
                                </div>
                            </div>
                        </Grid>
                        {
                            PoliticallyExposedPerson &&
                            <Grid item xs={12}>
                                <FormInput
                                    name="politicallyExposedPersonReason"
                                    control={control}
                                    label="Politically exposed person reason"
                                    error={!!errors.politicallyExposedPersonReason}
                                    helperText={errors.politicallyExposedPersonReason?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        }

                        <Grid item xs={12}>

                            <div className="flex items-center">
                                <FormSwitch
                                    name="domesticProminentInfluential"
                                    control={control}
                                    label="Are you a Domestic prominent influential person ?"
                                />
                                <div className="ml-2 mb-5">
                                    <SharedPopover type={"domesticProminentInfluential"} />
                                </div>
                            </div>

                        </Grid>
                        {
                            DomesticProminentInfluential &&
                            <Grid item xs={12}>
                                <FormInput
                                    name="domesticProminentInfluentialReason"
                                    control={control}
                                    label="Domestic prominent influential reason"
                                    error={!!errors.domesticProminentInfluentialReason}
                                    helperText={errors.domesticProminentInfluentialReason?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <div className="flex items-center">
                                <FormSwitch
                                    name="foreignProminentPublicOfficial"
                                    control={control}
                                    label="Are you a Foreign prominent public official?"
                                />
                                <div className="ml-2 mb-5">
                                    <SharedPopover type={"foreignProminentPublicOfficial"} />
                                </div>
                            </div>
                        </Grid>
                        {
                            ForeignProminentPublicOfficial &&
                            <Grid item xs={12}>
                                <FormInput
                                    name="foreignProminentPublicOfficialReason"
                                    control={control}
                                    label="Foreign prominent public official reason"
                                    error={!!errors.foreignProminentPublicOfficialReason}
                                    helperText={errors.foreignProminentPublicOfficialReason?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <div className="flex items-center">
                                <FormSwitch
                                    name="knownCloseAssociate"
                                    control={control}
                                    label="Are you a known close associate?"
                                />
                                <div className="ml-2 mb-5">
                                    <SharedPopover type={"foreignProminentPublicOfficial"} />
                                </div>
                            </div>
                        </Grid>
                        {
                            KnownCloseAssociate &&
                            <Grid item xs={12}>
                                <FormInput
                                    name="knownCloseAssociateReason"
                                    control={control}
                                    label="Known close associate reason"
                                    error={!!errors.knownCloseAssociateReason}
                                    helperText={errors.knownCloseAssociateReason?.message?.toString()}
                                    fullWidth
                                    required
                                />
                            </Grid>
                        }
                        <Box display="flex" justifyContent="center" alignItems="center" marginTop={'20px'} marginBottom={"40px"}>
                            <SharedButton type={'submit'} text={`next`}></SharedButton>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default Compliance;
