import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Container, CssBaseline, Grid, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import SnackbarContext from '../Context/SnackbarContext';
import { DebitOrderDays } from '../api/insurance-api.ts';
import FormInput from '../components/Form/FormInput';
import FormSelect from '../components/Form/FormSelect';
import SharedButton from '../components/Shared/SharedButton/SharedButton';
import SharedSwitch from '../components/Shared/SharedSwitch/SharedSwitch';
import { getDynamicWalletSchema } from '../validation/schema';
import { useSteps } from '../Context/StepContext.jsx';

export const C_WALLET_DETAILS_KEY = "cWallet";
export type CWalletDetails = {
    idNumber?: string | undefined;
    passportNumber?: string | undefined;
    premiumStartDate: Date;
    debitOrderDay: DebitOrderDays;
}

const CWallet: React.FC = () => {
    const { openSnackbar } = useContext(SnackbarContext);
    const [showPassportNumber, setShowPassportNumber] = useState(false);
    const { nextStep } = useSteps();

    const currentSchema = getDynamicWalletSchema(showPassportNumber);

    type CWalletInputs = z.infer<typeof currentSchema>;

    const { control, formState: { errors }, handleSubmit } = useForm({
        mode: 'onChange',
        resolver: zodResolver(currentSchema)
    });

    function isPassportType(input: CWalletInputs): input is { debitOrderDay?: DebitOrderDays, premiumStartDate?: string, passportNumber?: string } {
        return (input as { passportNumber?: string }).passportNumber !== undefined;
    }

    function isIdNumberType(input: CWalletInputs): input is { debitOrderDay?: DebitOrderDays, premiumStartDate?: string, idNumber?: string } {
        return (input as { idNumber?: string }).idNumber !== undefined;
      }

    const onSubmit = async (data: CWalletInputs) => {
        if(isPassportType(data)) {
            localStorage.setItem(C_WALLET_DETAILS_KEY, JSON.stringify({
                passportNumber: data.passportNumber,
                premiumStartDate: data.premiumStartDate,
                debitOrderDay: data.debitOrderDay
            }));
        } else if(isIdNumberType(data)) {
            localStorage.setItem(C_WALLET_DETAILS_KEY, JSON.stringify({ 
                idNumber: data.idNumber,
                premiumStartDate: data.premiumStartDate,
                debitOrderDay: data.debitOrderDay
            }));
        }

        openSnackbar("Wallet details saved successful", "success");
        nextStep();
    };

    return (
        <>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant='h3' sx={{ color: '#777774' }}>Catholic connect wallet details</Typography>
                    <Typography variant='h5'> Please provide the ID Number or passport number of the person responsible for the Catholic connect wallet payment.</Typography>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} container alignItems="center">
                                <Grid xs={2}>
                                    <SharedSwitch toggle={() => { setShowPassportNumber(!showPassportNumber); }} checked={showPassportNumber}></SharedSwitch>
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body1">I want to use my passport number instead</Typography>
                                </Grid>
                            </Grid>
                            { !showPassportNumber &&
                                <Grid item xs={12}>
                                    <FormInput
                                        name="idNumber"
                                        control={control}
                                        label="ID Number"
                                        error={!!errors.idNumber}
                                        helperText={errors.idNumber?.message?.toString() || ""}
                                        fullWidth
                                        required
                                        autoComplete="off"
                                    />
                                </Grid>
                            }
                            { showPassportNumber && 
                                <Grid item xs={12}>
                                    <FormInput
                                        name="passportNumber"
                                        control={control}
                                        label="Passport Number"
                                        error={!!errors.passportNumber}
                                        helperText={errors.passportNumber?.message?.toString()}
                                        fullWidth
                                        required
                                        autoComplete="off"
                                    />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <Typography variant='body2'>Premium Start date</Typography>
                                <FormInput
                                        name="premiumStartDate"
                                        control={control}
                                        label=""
                                        type="date"
                                        error={!!errors.premiumStartDate}
                                        helperText={errors.premiumStartDate?.message?.toString()}
                                        fullWidth
                                        required
                                    />
                            </Grid>
                            <Grid item xs={12}>
                                <FormSelect
                                    name="debitOrderDay"
                                    control={control}
                                    label="Debit Order Start Date"
                                    options={[
                                        { label: "1st", value: DebitOrderDays._1 },
                                        { label: "7th", value: DebitOrderDays._7 },
                                        { label: "15th", value: DebitOrderDays._15 },
                                        { label: "20th", value: DebitOrderDays._20 },
                                        { label: "25th", value: DebitOrderDays._25 },
                                        { label: "26th", value: DebitOrderDays._26 },
                                        { label: "27th", value: DebitOrderDays._27 },
                                        { label: "30th", value: DebitOrderDays._30 }
                                    ]}
                                    error={Boolean(errors.debitOrderDay)}
                                    helperText={errors.debitOrderDay?.message?.toString() || "Debit Order start day"}
                                />
                            </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="center" alignItems="center" marginTop={'20px'} marginBottom={"40px"}>
                            <SharedButton type={'submit'} text={`Proceed`}></SharedButton>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default CWallet;
