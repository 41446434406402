import React from 'react';
import Calculator from '../components/Calculator/Calculator';
import { useInsuranceCalculator } from '../Context/Calculator/InsuranceCalculatorContext';
import { useLoader } from '../Context/LoadingContext';

const Home: React.FC = () => {

    const { insurancePlan, calculatorLoading } = useInsuranceCalculator();
    const { showLoader, hideLoader } = useLoader();

    return (
        <>
            {calculatorLoading === null ? showLoader(true) : calculatorLoading ? showLoader(true) :<Calculator calculatorLoading={calculatorLoading} />}
        </>
    )
}

export default Home