import React from 'react';
import { Controller, Control, FieldValues, Path } from 'react-hook-form';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

interface Option {
  label: string;
  value: number | string;
}

interface FormSelectProps<TFieldValues extends FieldValues> {
  name: Path<TFieldValues>; // This ensures that name is only a string
  control: Control<TFieldValues>;
  label: string;
  options: Option[];
  error?: boolean;
  helperText?: string;
}

export function FormSelect<TFieldValues extends FieldValues>({
  name,
  control,
  label,
  options,
  error,
  helperText,
  ...rest
}: FormSelectProps<TFieldValues>) {
  return (
    <Controller
      name={name} // Explicitly cast name as string here
      control={control}
      render={({ field }) => (
        <FormControl fullWidth error={error}>
          <InputLabel id={`${name}-label`}>{label}</InputLabel>
          <Select
            {...field}
            labelId={`${name}-label`}
            label={label}
            value={field.value ?? ''}
            {...rest}
          >
            {options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}

export default FormSelect;
