import React from 'react'
import Header from '../components/Header/Header'
import { Outlet } from 'react-router-dom'

const Base: React.FC = () => {
    return (
        <>
            <Header />
            <Outlet />
        </>
    )
}

export default Base