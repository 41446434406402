import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Header.module.scss";
import AuthContext from '../../Context/AuthContext';
import { useTheme } from '@mui/material/styles';
import { Button } from "@mui/material";
import { getLogo } from '../../utils/themeLoader';

const Header = () => {
  const [isActive, setActive] = useState(false);
  const { isLoggedIn } = useContext(AuthContext);
  const theme = useTheme();
  const logo = getLogo();
  const openMenu = () => {
    setActive(!isActive);
  };

  let navigate = useNavigate();

  const routeChange = (path) => {
    setActive(false);
    navigate(path);
  };

  const currentPath = location.pathname;

  const switchRoute = () => {
    const targetRoute = currentPath.includes('base') ? '/' : '/base';
    navigate(targetRoute);
  };

  const buttonLabel = currentPath.includes('base') ? 'Last Wish Plan' : 'Starter Plan';

  return (
    <>
      <div className={styles["header-container"]}>
        <div className={styles["header-bar"]}>
          <div className={styles["header-logo-container"]}>
            <img src={logo} onClick={() => { routeChange('/') }} />
            <div className={styles["hamburger"]} onClick={openMenu}>
              <div className={isActive ? styles["hamburger-bar-active"] : styles["hamburger-bar"]}></div>
              <div className={isActive ? styles["hamburger-bar-active"] : styles["hamburger-bar"]}></div>
              <div className={isActive ? styles["hamburger-bar-active"] : styles["hamburger-bar"]}></div>
            </div>
          </div>
          <div className={styles["header-content-container"]}>
            <div className={isActive ? styles["header-content-active"] : styles["header-content"]}>
              <div className={isActive ? styles["header-content-active"] : styles["header-content"]}>
                {!isLoggedIn && <div className={isActive ? styles["header-content-active"] : styles["header-content"]} onClick={() => { routeChange('/login'); setActive(false); }}><Button sx={{ backgroundColor: theme.palette.primary }}>Login</Button></div>}
                <div className={isActive ? styles["header-content-active"] : styles["header-content"]} onClick={() => { switchRoute(); setActive(false); }}><Button sx={{ backgroundColor: theme.palette.primary }}> {buttonLabel}</Button></div>
                {/* {( isLoggedIn) && <div className={isActive ? styles["header-content-active"] : styles["header-content"]} onClick={() => { resumeJourney(); setActive(false); }}><Button sx={{ backgroundColor: theme.palette.primary }}> Resume Application</Button></div>} */}
                {/* { isLoggedIn && <div className={isActive ? styles["header-content-active"] : styles["header-content"]} onClick={() => { nextStep(); setActive(false); }}><Button sx={{ backgroundColor: theme.palette.primary }}> Next page </Button></div>} */}
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Header;
