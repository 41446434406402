import React from 'react'
import ReactDOM from 'react-dom/client'
import { Helmet } from 'react-helmet';
import App from './App.tsx'
import './index.css'
import ReactGA from "react-ga4";
import { BrowserRouter } from 'react-router-dom';
import { getTitle, getFavicon } from './utils/themeLoader.js';

ReactGA.initialize(import.meta.env.VITE_GA_4_TAG);

const title = getTitle();

ReactDOM.createRoot(document.getElementById('root')!).render(
    <><Helmet>
        <title>{title}</title>
        <link rel="icon" type="image/svg+xml" href={getFavicon()} />

    </Helmet><BrowserRouter>
            <App />
        </BrowserRouter></>,
)
