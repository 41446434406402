import Decimal from 'decimal.js';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { Client } from '../../api/insurance-api.ts';
import { getDynamicApiUrl } from '../../utils/themeLoader';
import { useLoader } from '../LoadingContext.jsx';
import { CalculatePremium } from './CalculationHelper';
import { InsurancePlan, Product, Rates } from './InsuranceModels';

interface InsuranceCalculatorContextProps {
  insurancePlan: InsurancePlan;
  setInsurancePlan: (insurancePlan: InsurancePlan) => void;
  calculatePremium: () => InsurancePlan;
  calculatorLoading: boolean | null;
  productId: string | null;
}
interface InsuranceCalculatorProviderProps {
  children: ReactNode;
}

const defaultInsurancePlan: InsurancePlan = {
  Principal: { age: 25, coverAmount: 10000, premiumAmount: new Decimal(0) },
  Family: {
    premiumAmount: new Decimal(0),
    waivedPremiumAmount: 0,
    Spouse: { selected: false, premiumWaiver: false },
    Children: { selected: false, amount: 0 }
  },
  Parent: { selected: false, parents: [] },
  ExtendedFamily: { selected: false, extendedMembers: [] },
  totalPremiumAmount: new Decimal(0)
};

const InsuranceCalculatorContext = createContext<InsuranceCalculatorContextProps>({
  insurancePlan: defaultInsurancePlan,
  setInsurancePlan: () => { },
  calculatePremium: () => defaultInsurancePlan,
  calculatorLoading: false,
  productId: null
});

export const InsuranceCalculatorProvider: React.FC<InsuranceCalculatorProviderProps> = ({ children }) => {

  const [insurancePlan, setInsurancePlan] = useState<InsurancePlan>(() => {
    const storedPlan = localStorage.getItem('InsurancePlan');
    return storedPlan ? JSON.parse(storedPlan) : defaultInsurancePlan;
  });

  const [calculatorLoading, setCalculatorLoading] = useState<boolean>(null);
  const [premiumRates, setPremiumRates] = useState<Product | null>(null);
  const [productId, setProductId] = useState<string | null>(() => {
    return localStorage.getItem('productId') || null;
  });
  const apiclient = new Client(getDynamicApiUrl());
  const { hideLoader } = useLoader();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCalculatorLoading(true);
        const response = await apiclient.getProductByTenantID();
        setPremiumRates(response.details.toJSON());
        setProductId(response.id);
      } catch (ex) {
        console.error("failed to fetch Product by tenantId", ex);
      } finally {
        setCalculatorLoading(false);
        hideLoader();
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem('InsurancePlan', JSON.stringify(insurancePlan));
  }, [insurancePlan]);

  useEffect(() => {
    if (productId) {
      localStorage.setItem('productId', productId);
    }
  }, [productId]);

  useEffect(() => {
    if (!insurancePlan.Parent.selected) {
      setInsurancePlan({
        ...insurancePlan, Parent: {
          ...insurancePlan.Parent, parents: []
        }
      })
    }

  }, [insurancePlan.Parent.selected])

  useEffect(() => {
    if (!insurancePlan.ExtendedFamily.selected) {
      setInsurancePlan({
        ...insurancePlan, ExtendedFamily: {
          ...insurancePlan.ExtendedFamily, extendedMembers: []
        }
      })
    }

  }, [insurancePlan.ExtendedFamily.selected])


  const calculatePremium = (): InsurancePlan | null => {

    // scaling up the decimal to match the api values
    const scaledGlobalVariables: Rates = {
      guardianRiskBinder: premiumRates.rates.guardianRiskBinder * 100,
      triarcBinder: premiumRates.rates.triarcBinder * 100,
      commission: premiumRates.rates.commission * 100,
      margin: premiumRates.rates.margin * 100,
      scorReinsurer: premiumRates.rates.scorReinsurer * 100,
      repatIndividual: premiumRates.rates.repatIndividual * 100,
      repatFamily: premiumRates.rates.repatFamily * 100
    };

    return CalculatePremium(insurancePlan, premiumRates, scaledGlobalVariables);

  };

  return (
    <InsuranceCalculatorContext.Provider value={{ insurancePlan, setInsurancePlan, calculatePremium, calculatorLoading, productId }}>
      {children}
    </InsuranceCalculatorContext.Provider>
  );
};

export const useInsuranceCalculator = () => useContext(InsuranceCalculatorContext);


