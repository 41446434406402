import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { CardActionArea } from '@mui/material';
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import React from "react";
import SharedButton from "../components/Shared/SharedButton/SharedButton";
import { useSteps } from "../Context/StepContext";
import { paymentMethod } from "../utils/enums";

export const PAYMENT_METHOD_KEY = "paymentMethod";

const PaymentMethod: React.FC = () => {
    const { selectedPayment, setSelectedPayment, nextStep } = useSteps();

    const handleCardClick = (paymentMethod: paymentMethod) => {
        if(paymentMethod != null || paymentMethod != undefined)
            setSelectedPayment(paymentMethod);
    };

    const handleProceed = () => {
        localStorage.setItem(PAYMENT_METHOD_KEY, selectedPayment);
        nextStep();
    }

    return (
        <>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <h1 style={{ margin: '0' }}>
                        Payment method
                    </h1>
                    <h3 style={{ textAlign: 'center' }}>
                        Please choose your preferred payment method by selecting either "Catholic Connect Wallet" or "Debit order" from the options below.
                    </h3>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>

                            <Box
                                sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: 2
                                }}
                                onClick={() => { handleCardClick(paymentMethod.CatholicWallet) }}
                            >
                                <Card sx={{ maxWidth: '100%' }}>
                                    <CardActionArea sx={{ display: 'flex' }}>
                                        <PhoneIphoneIcon sx={{ fontSize: 35, marginLeft: 1, color: '#777774' }} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                Catholic Connect Wallet
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                The payment for your funeral cover will be deducted from your Catholic Connect Wallet.
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    {selectedPayment && selectedPayment === paymentMethod.CatholicWallet && (
                                        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                                            <CheckCircleIcon sx={{ color: 'green', marginRight: 1, marginTop: 1 }} />
                                        </Box>
                                    )}
                                </Card>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>

                            <Box
                                sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: 2,
                                    width: '100%'
                                }}
                                onClick={() => { handleCardClick(paymentMethod.DebitOrder) }}
                            >
                                <Card sx={{ maxWidth: '100%' }}>
                                    <CardActionArea sx={{ display: 'flex' }}>
                                        <AccountBalanceIcon sx={{ fontSize: 35, marginLeft: 1, color: '#777774' }} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                Debit order
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                The funeral cover will be charged as a monthly debit order.
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    {selectedPayment && selectedPayment === paymentMethod.DebitOrder && (
                                        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                                            <CheckCircleIcon sx={{ color: 'green', marginRight: 1, marginTop: 1 }} />
                                        </Box>
                                    )}
                                </Card>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                    </Grid>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        marginBottom={"40px"}>
                        <SharedButton handleOnClick={handleProceed} text={`Proceed`}></SharedButton>
                    </Box>
                    <Grid container justifyContent="flex-end">
                    </Grid>
                </Box>
            </Container>
        </>
    );
};

export default PaymentMethod;