import { Container } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header/Header';

const Navigator: React.FC = () => {

  return (
    <>
      <Header />
      <Outlet></Outlet>
    </>
  );
};

export default Navigator;
