import { zodResolver } from "@hookform/resolvers/zod";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import React, { useContext, useRef } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import AuthContext from "../Context/AuthContext";
import { useInsuranceCalculator } from '../Context/Calculator/InsuranceCalculatorContext';
import { useLoader } from "../Context/LoadingContext";
import SnackbarContext from "../Context/SnackbarContext";
import { CatholicConnectWalletJson, Client, DebitOrderJson, ICatholicConnectWalletJson, IDebitOrderJson, IPremiumAccept_Request, IPremiumCreate_Request, IPremiumCreate_Request_FamilyDto, IPremiumCreate_Request_FamilyDto_ChildrenJson, IPremiumCreate_Request_FamilyDto_SpouseJson, IPremiumCreate_Request_PrincipalDto, PremiumAccept_Request, PremiumCreate_Request, PremiumCreate_Request_ExtendedFamilyMemberDto, PremiumCreate_Request_FamilyDto, PremiumCreate_Request_FamilyDto_ChildrenJson, PremiumCreate_Request_FamilyDto_SpouseJson, PremiumCreate_Request_ParentDto, PremiumCreate_Request_PrincipalDto } from "../api/insurance-api";
import SharedButton from "../components/Shared/SharedAddButton/SharedAddButton";
import { bankingSchema, premiumSchema } from "../validation/schema";
import { ConditionsText, DebitOrderAuthText } from './HtmlTexts/Content';
import { Grid } from "@mui/material";
import FormInput from "../components/Form/FormInput";
import { getDynamicApiUrl } from "../utils/themeLoader";
import { useSteps } from "../Context/StepContext";
import { BANKING_DETAILS_KEY } from "./Banking";
import { z } from "zod";
import { PAYMENT_METHOD_KEY } from "./PaymentMethod";
import { paymentMethod } from "../utils/enums";
import { C_WALLET_DETAILS_KEY, CWalletDetails } from "./CWallet";

const TermsAndConditions: React.FC = () => {

  const { customerId } = useContext(AuthContext);
  const { insurancePlan, productId } = useInsuranceCalculator();
  const navigate = useNavigate();
  const theme = useTheme();
  const { showLoader, hideLoader } = useLoader();
  const { openSnackbar } = useContext(SnackbarContext);
  const apiclient = new Client(getDynamicApiUrl());
  const { resetSteps } = useSteps();

  const routeChange = (path) => {
    navigate(path);
  };

  const sigCanvas = useRef<SignatureCanvas | null>(null);

  const { control, watch, formState: { errors }, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: zodResolver(premiumSchema),
  });

  const premiumAccepted = watch("premiumAccepted");

  const onSubmit: SubmitHandler<IPremiumAccept_Request> = async () => {

    const principalDto: IPremiumCreate_Request_PrincipalDto = {
      age: insurancePlan.Principal.age,
      cover: insurancePlan.Principal.coverAmount,
      premium: Number(insurancePlan.Principal.premiumAmount),

    };

    let spouseDto: IPremiumCreate_Request_FamilyDto_SpouseJson | undefined;
    if (insurancePlan.Family.Spouse && insurancePlan.Family.Spouse.selected) {
      spouseDto = {
        waiverPremium: false
      };
    }

    let childrenDto: IPremiumCreate_Request_FamilyDto_ChildrenJson | undefined;
    if (insurancePlan.Family.Children && insurancePlan.Family.Children.selected) {
      childrenDto = {
        amount: insurancePlan.Family.Children.amount
      };
    }
    ///family

    let spouse;
    if (spouseDto) {
      spouse = new PremiumCreate_Request_FamilyDto_SpouseJson(spouseDto);
    }

    let children;
    if (childrenDto) {
      children = new PremiumCreate_Request_FamilyDto_ChildrenJson(childrenDto);
    }

    const familyDto: IPremiumCreate_Request_FamilyDto = {
      premium: Number(insurancePlan.Family.premiumAmount),
      waiveredPremium: 0,
      spouse: undefined,
      children: undefined,
    };

    if (insurancePlan.Family.Children.selected || insurancePlan.Family.Spouse.selected) {
      familyDto.spouse = spouse ? new PremiumCreate_Request_FamilyDto_SpouseJson(spouseDto) : undefined;
      familyDto.children = children ? new PremiumCreate_Request_FamilyDto_ChildrenJson(childrenDto) : undefined;
    }
    // parents
    let parentDtos: PremiumCreate_Request_ParentDto[] = [];
    if (insurancePlan.Parent.selected) {
      parentDtos = insurancePlan.Parent.parents.map(parent => {
        const preparedParentData = {
          age: parent.age,
          cover: parent.coverAmount,
        };
        return PremiumCreate_Request_ParentDto.fromJS(preparedParentData);
      });
    }

    const extendedDtos: PremiumCreate_Request_ExtendedFamilyMemberDto[] = [];
    if (insurancePlan.ExtendedFamily.selected) {

      insurancePlan.ExtendedFamily.extendedMembers.map(extended => {
        const preparedExtendedFamilyData = {
          age: extended.age,
          cover: extended.coverAmount,
        };
        return PremiumCreate_Request_ExtendedFamilyMemberDto.fromJS(preparedExtendedFamilyData);
      });
    }

    const premiumRequest: IPremiumCreate_Request = {
      productId: productId,
      principal: new PremiumCreate_Request_PrincipalDto(principalDto),
      family: new PremiumCreate_Request_FamilyDto(familyDto),
      parents: parentDtos,
      extendedFamilyMembers: extendedDtos,
      totalPremium: Number(insurancePlan.totalPremiumAmount),
    };

    const premiumAccept = {} as IPremiumAccept_Request;
    const paymentMethodJson = localStorage.getItem(PAYMENT_METHOD_KEY);

    if(paymentMethodJson === paymentMethod.DebitOrder || paymentMethodJson === null)
    {
      type BankInputs = z.infer<typeof bankingSchema>;
      const bankingDetailsJson = localStorage.getItem(BANKING_DETAILS_KEY);
      const bankingDetails = JSON.parse(bankingDetailsJson) as BankInputs;

      const debitOrderJson = new DebitOrderJson({} as IDebitOrderJson);
      debitOrderJson.accountHolderName = bankingDetails.accountHolderName;
      debitOrderJson.accountNumber = bankingDetails.accountNumber;
      debitOrderJson.accountType = bankingDetails.accountType;
      debitOrderJson.bankName = bankingDetails.bankName;
      debitOrderJson.branchCode = bankingDetails.branchCode;
      debitOrderJson.debitOrderDay = bankingDetails.debitOrderDay
      debitOrderJson.premiumStartDate = new Date(bankingDetails.premiumStartDate);
      debitOrderJson.$type = "DebitOrder";

      premiumAccept.paymentDetails = debitOrderJson;
    }
    else if (paymentMethodJson === paymentMethod.CatholicWallet) {
      const cWalletJson = localStorage.getItem(C_WALLET_DETAILS_KEY);

      if(cWalletJson) {        
        const cWalletValue = JSON.parse(cWalletJson) as CWalletDetails;

        console.log(cWalletValue)
        
        const cWallet = new CatholicConnectWalletJson({} as ICatholicConnectWalletJson);
        cWallet.$type = 'CatholicConnectWallet',
        cWallet.idNumber = cWalletValue.idNumber ?? "",
        cWallet.passportNumber = cWalletValue.passportNumber ?? "",
        cWallet.premiumStartDate = new Date(cWalletValue.premiumStartDate),
        cWallet.debitOrderDay = cWalletValue.debitOrderDay,

        premiumAccept.paymentDetails = cWallet;
      }
    }
    else {
      openSnackbar("Please select a valid payment method", "error");
      return;
    }

    try {
      showLoader();
      await apiclient.customerPremiumCreate(customerId, new PremiumCreate_Request(premiumRequest));
      await apiclient.customerPremiumAccept(customerId, new PremiumAccept_Request(premiumAccept));
      openSnackbar("Customer application complete", "success");
      resetSteps();
      routeChange("/");
    } catch (error) {
      console.error(error);
      openSnackbar("Failed - please try again", "error");
    }
    finally {
      hideLoader();
    }
  };

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant='h5'>Terms & Conditions</Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 3 }}>

            <h1>
              Please read and accept our terms & conditions
            </h1>
            <Accordion sx={{ backgroundColor: theme.palette.primary.light, borderRadius: '4px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#fff', fontSize: '40px' }} />}
              >
                <Typography sx={{ color: '#fff' }}>Terms and Conditions</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: theme.palette.primary.light, borderRadius: '4px' }}>
                  <ConditionsText></ConditionsText>
              </AccordionDetails>
            </Accordion>

            <br></br>

              <Accordion sx={{ backgroundColor: theme.palette.primary.light, borderRadius: '4px' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: '#fff', fontSize: '40px' }} />}
                >
                  <Typography sx={{ color: '#fff' }}>Debit Order Authorization</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: theme.palette.primary.light, borderRadius: '4px' }}>
                  <DebitOrderAuthText></DebitOrderAuthText>
                </AccordionDetails>
              </Accordion>

            <h3 style={{ textAlign: 'center' }}>
              To confirm your agreement to the terms and conditions, please provide your signature in the box below.
            </h3>
            <div className={"shadow-md mb-2.5 rounded-lg w-full sm:w-auto"}>
              <SignatureCanvas
                penColor="black"
                canvasProps={{ className: "w-[500px] h-[200px] sm:w-full" }}
                ref={sigCanvas}
              />
            </div>
            <Button
              onClick={() => sigCanvas.current.clear()}
              variant="text"
              color="error"
            >Clear signature</Button>

            <h3 style={{ display: 'flex', textAlign: 'center' }}>
              My signature above serves as confirmation that I have reviewed and accepted the terms and conditions outlined above, and that I am the authorized signatory of this document. Please click on the checkbox below to confirm
            </h3>

            <Grid item xs={12}>
              <FormInput
                name="reference"
                control={control}
                label="Reference"
                error={!!errors.reference}
                helperText={errors.reference?.message?.toString()}
                fullWidth
                required
              />
            </Grid>
            <Controller
              name="premiumAccepted"
              control={control}
              render={({ field }) => (
                <Checkbox
                  checked={field.value ?? false}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginBottom={"40px"}>
              <SharedButton
                disabled={!premiumAccepted}
                handleOnClick={handleSubmit(onSubmit)}
                text={`Sign up`}
              ></SharedButton>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default TermsAndConditions;
