// theme.js
import { createTheme } from '@mui/material/styles';

export const cartrackTheme = createTheme({
  palette: {
    primary: {
      main: '#f47735',
    },
    secondary: {
      main: '#19857b',
    },

  },
  typography: {
    fontFamily: 'Lato',
    h1: {
      fontSize: '3.4rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '3rem',
    },
    h3: {
      fontSize: '2.4rem',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
        },
        track: {
          borderRadius: '3px',
          height: '10px',
        },
        thumb: {
          height: '40px',
          width: '40px',
          backgroundColor: '#f1f0f0',

        },
        rail: {
          borderRadius: '3px',
          height: '10px',
          backgroundColor: 'rgb(205, 205, 205)',
        },
      },
    },

    custom: {
      drawerWidth: 240,
    },
  },
});

export default cartrackTheme;
