import cartrackTheme from '../themes/cartrackTheme';
import catholicTheme from '../themes/catholicTheme';
import cartrackLogo from '../assets/img/logos/cartrackLogo.png';
import catholicLogo from '../assets/img/logos/catholicLogo.png';

let host = window.location.host
let subdomain = host.split('.')[0]

const cartrackSubdomain = import.meta.env.VITE_CARTRACK_SUBDOMAIN;
const catholicSubdomain = import.meta.env.VITE_CATHOLICCONNECT_SUBDOMAIN;
const apiUrl =  import.meta.env.VITE_API_URL;

const url = new URL(apiUrl);

console.log("🚀 ~ hostname:", `${cartrackSubdomain}.${url.hostname}`);

const getTheme = () => {

    switch (subdomain) {
        case cartrackSubdomain:
            return cartrackTheme;
        case catholicSubdomain:
            return catholicTheme;
        default:
            return cartrackTheme;
    }
};

const getTitle = () => {

    switch (subdomain) {
      case cartrackSubdomain:
        return 'Cartrack';
      case catholicSubdomain:
        return 'Catholic connect ';
      default:
        return 'Cartrack';
    }
  };

const getLogo = () => {

    switch (subdomain) {
        case cartrackSubdomain:
            return cartrackLogo;
        case catholicSubdomain:
            return catholicLogo;
        default:
            return cartrackLogo;
    }
};

const getDynamicApiUrl= () => {

    const isLocal = import.meta.env.VITE_API_IS_LOCAL;
    const host = isLocal ? url.host : url.hostname // includes port locally
    const protocol = 'https://';

    switch (subdomain) {
        case cartrackSubdomain:
            return `${protocol}${cartrackSubdomain}-api.${host}`;
        case catholicSubdomain:
            return `${protocol}${catholicSubdomain}-api.${host}`;
        default:
            return `${protocol}${cartrackSubdomain}-api.${host}`;
    }
};

const getFavicon = () => {

    switch (subdomain) {
        case cartrackSubdomain:
            return cartrackLogo;
        case catholicSubdomain:
            return catholicLogo;
        default:
            return cartrackLogo;
    }
};

export { getTheme, getLogo, getTitle, getDynamicApiUrl,getFavicon };


