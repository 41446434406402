import { Tooltip } from "@mui/material";
import Slider from '@mui/material/Slider';
import { useTheme } from '@mui/material/styles';
// import Typography from '@mui/material';
// import thumbPic from '../../../assets/img/fav.png';

const SharedSlider = ({ sliderValue, handleSliderChange, value, min, max, text, step, onblur }) => {

    const theme = useTheme();
    
    const thumbComponent = ({ value, index, ...props }) => {
        return (
            <Tooltip
                key={index}
                title={value}
                placement="top"
                classes={{ tooltip: props.classes.tooltip }}
            >
                <span {...props} className="text">
                    {value}
                </span>
            </Tooltip>
        );
    };


    return (
        <>
            <Slider
                defaultValue={sliderValue}
                ThumbComponent={(value, index, ...props) => (
                    <thumbComponent value={'hello'} index={index} {...props} />
                )}
                onChange={handleSliderChange}
                onBlur={onblur}
                value={value}
                aria-label="Default"
                valueLabelDisplay="auto"
                step={step}
                min={min}
                max={max}

            />

        </>
    )
}

export default SharedSlider